import { useMutation } from '@apollo/client/index.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import PhaseGroupForm, { FormValues, validate } from '@asaprint/asap/components/forms/PhaseGroupForm.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import authorized from '@asaprint/asap/decorators/authorized.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import { BUTTON_UPDATE } from '@asaprint/asap/locales/client.js';
import { ADMIN_PHASE_GROUPS_ROUTE, DASHBOARD_ROUTE } from '@asaprint/asap/routes.js';
import {
  EditPhaseGroup_Load,
  EditPhaseGroup_Save,
} from '@asaprint/asap/routes/__authenticated/admin/phase-groups/$id.edit.graphql';
import {
  EditPhaseGroup_LoadQuery,
  EditPhaseGroup_LoadQueryVariables,
  EditPhaseGroup_SaveMutation,
  EditPhaseGroup_SaveMutationVariables,
} from '@asaprint/asap/schema.client.types.js';
import { Permission } from '@asaprint/common/access.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import Loading from '@engined/client/components/Loading.js';
import { ConnectedSubmitButtons } from '@engined/client/components/SubmitButtons.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

const logger = getLogger('@asap/routes/admin/phase-groups/$id.edit');

interface OwnProps {}

type Props = OwnProps;

const Page: React.FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocale();
  const { loading, data, error } = useQuery<EditPhaseGroup_LoadQuery, EditPhaseGroup_LoadQueryVariables>(
    EditPhaseGroup_Load,
    {
      variables: { id },
    },
  );
  const [saveExecute] = useMutation<EditPhaseGroup_SaveMutation, EditPhaseGroup_SaveMutationVariables>(
    EditPhaseGroup_Save,
  );

  const onSubmit: OnSubmit<FormValues> = async ({ ...values }) => {
    try {
      const response = await saveExecute({
        variables: {
          input: values,
        },
      });
      enqueueSnackbar(
        <Box>
          Úspešne ste upravili skupinu fáz <strong>{response.data.phaseGroupSave.name}</strong>.
        </Box>,
        {
          variant: 'success',
        },
      );
      navigate(ADMIN_PHASE_GROUPS_ROUTE, { state: { ignoreFormPrompt: true } });
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  const phaseGroup = data?.phaseGroup;
  const initialValues = useMemo(
    (): FormValues =>
      phaseGroup && {
        id: phaseGroup.id,
        updatedAt: phaseGroup.updatedAt,
        name: phaseGroup.name ?? '',
        position: phaseGroup.position,
      },
    [phaseGroup],
  );

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faLayerGroup} /> Úprava skupiny
          </>
        }
      />

      <PagePaper>
        {error || (loading && !data) ? (
          <Loading error={error} />
        ) : (
          <Form defaultValues={initialValues} onSubmit={onSubmit} validate={validate}>
            <PhaseGroupForm showPosition />
            <ConnectedSubmitButtons submitLabel={t(BUTTON_UPDATE)} backTo={ADMIN_PHASE_GROUPS_ROUTE} />
          </Form>
        )}
      </PagePaper>
    </>
  );
};

export default authorized(Permission.PhaseGroupsManage)(Page);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  const result = await apollo.query<EditPhaseGroup_LoadQuery, EditPhaseGroup_LoadQueryVariables>({
    query: EditPhaseGroup_Load,
    variables: { id: params.id },
  });
  return result.data;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Administrácia',
    },
    {
      text: 'Fázy produktu - skupiny',
      to: ADMIN_PHASE_GROUPS_ROUTE,
    },
    {
      text: 'Úprava skupiny fáz',
    },
  ],
  meta: ({ meta }: MetaFunctionArgs) => ({
    title: `Úprava skupiny fáz | Fázy produktu - skupiny | Administrácia | ${meta.title}`,
  }),
};
